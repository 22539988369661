/**
 * Created by reyra on 5/21/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisabledControlDirective } from './disabled.directive';
import { TextMaskDirectiveModule } from '../../directives/text-mask/text-mask.directive.module';
import { InputPrefix } from './prefix.component';
import { InputSuffix } from './suffix.component';
import { InputComponent } from './input.component';

@NgModule({
  declarations: [
    InputComponent,
    DisabledControlDirective,
    InputPrefix,
    InputSuffix,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TextMaskDirectiveModule,
    ReactiveFormsModule,
  ],
  exports: [
    InputComponent,
    InputPrefix,
    InputSuffix,
    FormsModule,
    ReactiveFormsModule,
    TextMaskDirectiveModule,
    DisabledControlDirective,
  ],
})
export class InputModule {}
