import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TextMaskConfig } from './text-mask.directive';
import * as vanillaTextMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Injectable()
export class TextMaskService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  maskInput(inputElement: HTMLElement, options: TextMaskConfig = {}) {
    const config: any = Object.assign(
      {
        inputElement,
        guide: false,
        keepCharPositions: true,
      },
      options,
    );

    const mask: any = (raw: string) => {
      const regex: any =
        typeof config['mask'] === 'function'
          ? config['mask'](raw)
          : config['mask'];
      if (regex.length)
        this.renderer.setAttribute(inputElement, 'maxlength', regex.length);
      return regex;
    };

    return vanillaTextMask.maskInput({
      ...config,
      mask,
    });
  }
}
