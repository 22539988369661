/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextMaskDirective } from './text-mask.directive';
import { TextMaskService } from './text-mask.service';

@NgModule({
  declarations: [TextMaskDirective],
  imports: [CommonModule],
  exports: [TextMaskDirective],
  providers: [TextMaskService],
})
export class TextMaskDirectiveModule {}
