import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TextMaskService } from './text-mask.service';

export class TextMaskConfig {
  mask?: (string | RegExp)[] | ((raw: string) => (string | RegExp)[]) | false;
  guide?: boolean;
  placeholderChar?: string;
  pipe?: (
    conformedValue: string,
    config: TextMaskConfig,
  ) => false | string | object;
  keepCharPositions?: boolean;
  showMask?: boolean;
}

@Directive({
  selector: '[textMask]',
  providers: [],
  standalone: false,
})
export class TextMaskDirective implements AfterViewInit {
  @Input('textMask') textMask: any = [];
  @Input('textMaskConfig') textMaskConfig: TextMaskConfig = {
    guide: false,
    keepCharPositions: true,
  };
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  private vanillaTextMask;
  private inputElement: HTMLElement | HTMLInputElement | null;

  constructor(
    private elementRef: ElementRef,
    private vanillaTextMaskService: TextMaskService,
  ) {}

  ngAfterContentInit(): void {}

  _setupMask() {
    if (!this.inputElement) {
      if (this.LayoutElement.tagName.toUpperCase() === 'INPUT') {
        // `textMask` directive is used directly on an input element
        this.inputElement = this.LayoutElement;
      } else {
        // `textMask` directive is used on an abstracted input element, `md-input-container`, etc
        this.inputElement = this.LayoutElement.querySelector('input');
      }
    }
  }

  ngAfterViewInit(): void {
    this._setupMask();
    const config: TextMaskConfig = {
      mask: this.textMask,
      ...this.textMaskConfig,
    };

    this.vanillaTextMask = this.vanillaTextMaskService.maskInput(
      <HTMLElement>this.inputElement,
      config,
    );
  }

  private get LayoutElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}
